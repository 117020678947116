<template>
  <v-form class="px-5" ref="form" v-model="valid" lazy-validation>
    <v-select
      v-model="paymentInfo.typeId"
      :items="paymentTypesList"
      item-text="text"
      item-value="value"
      label="Payment Method"
    ></v-select>
    <v-text-field
      v-if="isDirectDebit"
      v-model="form.bankName"
      label="Bank Name"
      required
      ref="bankName"
      :rules="bankNameRules"
      @input="bankNameChanged"
    ></v-text-field>

    <v-text-field
      v-if="isDirectDebit"
      v-model="form.accountName"
      label="Account Name"
      required
      ref="accountName"
      :rules="accountNameRules"
      @input="accountNameChanged"
    ></v-text-field>
    <v-text-field
      v-if="isDirectDebit"
      v-model="form.sortCode"
      label="Sort Code"
      @paste="handlePaste"
      required
      ref="sortCode"
      :rules="sortCodeRules"
      @keypress="isSorCodeNumberKey"
    ></v-text-field>
    <v-text-field
      v-if="isDirectDebit"
      v-model="form.accountNumber"
      label="Account Number"
      @paste="handlePasteAccountNumber"
      required
      ref="accountNumber"
      :rules="acountNumberRules"
      @keypress="isAccountNoNumberKey($event)"
    ></v-text-field>
    <div class="mt-4">
      <v-btn depressed color="accent" block dark large @click="savePayment">
        Save changes
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import {
  mergeExistingEntriesIntoForm,
  changesSaved,
  somethingWentWrong,
} from "@/helpers/utilities"
import api from "@/store/api"

export default {
  data() {
    return {
      valid: true,
      form: {
        bankName: "",
        accountName: "",
        sortCode: "",
        accountNumber: "",
      },
      payMethods: [],
      sortCodeRules: [(value) => !!value || "Please Provide Sord Code"],
      bankNameRules: [(value) => !!value || "Please Provide Bank Name"],
      acountNumberRules: [
        (value) => !!value || "Please Provide Account Number",
      ],
      accountNameRules: [(value) => !!value || "Please Provide Account Name"],
      errorMessages: "",
      formHasErrors: false,
    }
  },
  computed: {
    ...mapGetters("addresses", [
      "paymentInfo",
      "loadingPaymentInfo",
      "selectedAddress",
    ]),
    ...mapGetters("forms", ["paymentTypesList"]),
    ...mapGetters("ui", ["formEditMode"]),
    isDirectDebit() {
      const method = this.paymentInfo.typeId
      return method === 1
    },
    paymentMethod() {
      return this.isDirectDebit ? "Direct Debit" : "Cash & Cheque"
    },
  },
  whatch: {
    paymentInfo() {
      this.bindPaymentsInformation()
    },
    formEditMode(value) {
      if (!value) {
        this.$refs.form.reset()
      } else {
        this.bindPaymentsInformation()
      }
    },
    // menu(val) {
    //   val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"))
    // },
  },
  created() {
    this.bindPaymentsInformation()
  },
  methods: {
    ...mapActions("ui", ["closeForm"]),
    ...mapActions("addresses", ["fetchPaymentInfo"]),
    // ...mapActions("addresses", ["updateAddress", "createAddress"]),
    bindPaymentsInformation() {
      if (this.formEditMode) {
        Object.entries(this.paymentInfo).forEach(
          mergeExistingEntriesIntoForm(this.form)
        )
      }
    },
    handlePaste(event) {
      let pasteContent = (event.clipboardData || window.clipboardData).getData(
        "text"
      )
      pasteContent = pasteContent.replace(/\s/g, "") // Remove spaces from pasted content

      if (!/^\d+$/.test(pasteContent)) {
        event.preventDefault()
      } else {
        // Set the cleaned content to the input
        event.preventDefault()
        this.form.sortCode = pasteContent
      }
    },
    handlePasteAccountNumber(event) {
      let pasteContent = (event.clipboardData || window.clipboardData).getData(
        "text"
      )
      pasteContent = pasteContent.replace(/\s/g, "") // Remove spaces from pasted content

      if (!/^\d+$/.test(pasteContent)) {
        event.preventDefault()
      } else {
        // Set the cleaned content to the input
        event.preventDefault()
        this.form.accountNumber = pasteContent
      }
    },

    bankNameChanged() {
      this.form.bankName = this.form.bankName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    accountNameChanged() {
      this.form.accountName = this.form.accountName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },

    isSorCodeNumberKey(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (this.form.sortCode.length >= 6) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
    isAccountNoNumberKey(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (this.form.accountNumber.length >= 8) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },

    async savePayment() {
      if (
        this.paymentInfo.typeId === 1 &&
        (!this.form.bankName ||
          !this.form.accountName ||
          !this.form.accountNumber ||
          !this.form.sortCode)
      ) {
        const form = {
          sortCode: this.form.sortCode,
          accountNumber: this.form.accountNumber,
          bankName: this.form.bankName,
          accountName: this.form.accountName,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }

      const filteredPayment = this.paymentTypesList.filter(
        (paymentType) => paymentType.value === this.paymentInfo.typeId
      )
      const paymentData = {
        id: this.paymentInfo.id,
        type: filteredPayment ? filteredPayment[0].text : "",
        typeId: this.paymentInfo.typeId ? this.paymentInfo.typeId : 0,
        bankName: this.form.bankName,
        sortCode: this.form.sortCode,
        accountName: this.form.accountName,
        accountNumber: this.form.accountNumber,
        addressId: this.paymentInfo.addressId,
      }
      try {
        const response = await api.post("savePayment", paymentData)
        if (response && response.status === 200) {
          changesSaved()
          this.closeForm()
        } else {
          somethingWentWrong()
        }
      } catch (error) {
        somethingWentWrong()
      }
    },
  },
}
</script>
